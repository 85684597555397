import React from 'react';
import 'flickity-fade';
import 'flickity-as-nav-for';
import Flickity from 'react-flickity-component';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Link from '../Link';

import {
  TabbedContentStyled,
  SectionHeaderStyled,
  SliderStyled,
  SlideStyled,
  SliderWrapperStyled,
  SliderNav,
} from './tabbed-content.styled';

const flickityOptions = {
  initialIndex: 0,
  pauseAutoPlayOnHover: false,
  fade: true,
  cellAlign: 'center',
  prevNextButtons: false,
};

const flickityNavOptions = {
  initialIndex: 0,
  pauseAutoPlayOnHover: false,
  pageDots: false,
  asNavFor: '.tab-carousel',
  contain: true,
  cellAlign: 'center',
  prevNextButtons: false,
};

export default function TabbedContent(props) {
  const { sectionTitle, tabsContent, featuredLogos } = props;
  const slide = (content, image) => {
    return (
      <SlideStyled>
        <div className="slider-content">
          {/* <div className="flex flex-col md:flex-row md:items-center">
            <h4 className="h4">{title}</h4>
          </div> */}
          {/* eslint-disable-next-line react/no-danger */}
          <div className="p" dangerouslySetInnerHTML={{ __html: content }} />

          {/* <Link to="#" className="more hidden md:flex">
            Read more
          </Link> */}
        </div>
        <div className="slider-image">
          <GatsbyImage image={getImage(image.localFile)} alt="slider-img" />
        </div>

        {/* <Link to="#" className="more flex md:hidden">
          Read more
        </Link> */}
      </SlideStyled>
    );
  };

  return (
    <TabbedContentStyled className="tabbed-section ves-section">
      <div className="ves-container">
        <SectionHeaderStyled>
          <div className="image-wrp">
            {featuredLogos.map((item) => (
              <img key={item.image.id} src={item.image.localFile.publicURL} alt="logo" />
            ))}
          </div>
          {sectionTitle && <h2 className="h2">{sectionTitle}</h2>}
          {/* <h4 className="h4 tagline">we have been lucky to work with an amazing clientele.</h4> */}
        </SectionHeaderStyled>

        <SliderWrapperStyled>
          <SliderNav>
            <Flickity
              className="carousel-nav" // default ''
              elementType="div" // default 'div'
              options={flickityNavOptions} // takes flickity options {}
              disableImagesLoaded={false} // default false
              reloadOnUpdate // default false
            >
              {tabsContent?.map((item) => (
                <div className="btn" key={item.tabTitle}>
                  {item.tabTitle}
                </div>
              ))}
            </Flickity>
          </SliderNav>

          <SliderStyled>
            <div className="slider-container flex">
              <Flickity
                className="tab-carousel" // default ''
                elementType="div" // default 'div'
                options={flickityOptions} // takes flickity options {}
                disableImagesLoaded={false} // default false
                reloadOnUpdate // default false
                static // default false
                // flickityRef={(c) => {
                //   flkty = c;
                // }}
              >
                {tabsContent?.map((item) => (
                  <React.Fragment key={item.tabTitle}>
                    {slide(item.content, item.image)}
                  </React.Fragment>
                ))}
              </Flickity>
            </div>
          </SliderStyled>
        </SliderWrapperStyled>
      </div>
    </TabbedContentStyled>
  );
}
