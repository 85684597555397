import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Link from '../Link';

import { ProductCardStyled, ProductContent } from './product-card.styled';

function ProductCard({ title, description, image, link }) {
  return (
    <ProductCardStyled className="group product-item">
      {image && <GatsbyImage image={getImage(image)} alt={title} />}
      <ProductContent>
        <div className="top-content">
          <h3 className="h3">{title}</h3>
          <p className="p">{description}</p>
        </div>
        {link && <span className="more">Ask About This Product</span>}
      </ProductContent>
      {link && <Link to={link} className="card-link" />}
    </ProductCardStyled>
  );
}

export default ProductCard;

ProductCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
};
