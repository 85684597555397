import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import Link from '../Link';
import ChevronDown from '../../images/chevron-down.svg';

import { BreadcrumbStyled } from './breadcrumb.styled';

export function Breadcrumb({ data, lastItemActive }) {
  const ItemCount = data?.length;

  return (
    <BreadcrumbStyled>
      <div className="ves-container">
        <Link to="/">Home</Link>
        {ItemCount &&
          data?.map(({ url, title }, i) => (
            <React.Fragment key={uuidv4()}>
              <ChevronDown />
              {ItemCount - 1 === i /* && !lastItemActive */ ? (
                <span>{title || null}</span>
              ) : (
                <Link className="bread-links" to={url}>
                  {title || null}
                </Link>
              )}
            </React.Fragment>
          ))}
      </div>
    </BreadcrumbStyled>
  );
}

export default Breadcrumb;
