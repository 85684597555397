import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Flickity from 'react-flickity-component';
import { v4 as uuidv4 } from 'uuid';

import Link from '../Link';

import { PackageWrpStyled } from './packages.styled';

const flickityOptions = {
  initialIndex: 0,
  autoplay: true,
  pauseAutoPlayOnHover: false,
  pageDots: true,
  contain: true,
  watchCSS: true,
  prevNextButtons: true,
  groupCells: true,
};

function bgColorClass(color) {
  switch (color) {
    case 'blue':
      return 'bg-primary';
    case 'green':
      return 'bg-success';
    case 'orange':
      return 'bg-secondary-orange';
    case 'red':
      return 'bg-secondary-darkerOrange';
    case 'maroon':
      return 'bg-secondary-maroon';
    case 'purple':
      return 'bg-secondary-purple';
    default:
      return 'bg-primary';
  }
}

export function PackagesItem({
  bgColor,
  capacity,
  packageDetails,
  packageName,
  diyKitPrice,
  supplyAndInstallKitPrice,
  requestQuoteLink,
  // comparePackagesLink,
  // flickityResize,
}) {
  // const [comparePackagesClass, setComparePackagesClass] = useState('');

  // const flickityResizeCall = () => {
  //   setComparePackagesClass('show');
  //   // flickityResize(true);
  // };

  return (
    <div className={`packages-item ves-transition ${bgColorClass(bgColor)}`}>
      <div className="top-content">
        {capacity && <span className="wats">{capacity}</span>}

        {packageName && (
          <div className="package-name-wrp">
            <div className="package-name-inner-wrp">
              <span className="package-name">
                Raivotu <span className="package-name-number">{packageName}</span>
              </span>
            </div>
          </div>
        )}

        {diyKitPrice && (
          <div className="price-main-wrp">
            <p>DIY Kit</p>
            <div className="prices-wrp">
              FJD <span className="price">{diyKitPrice}</span> <span className="vip">VIP</span>
            </div>
          </div>
        )}

        {supplyAndInstallKitPrice && (
          <div className="price-main-wrp">
            <p>Supply and Install</p>
            <div className="prices-wrp">
              FJD <span className="price">{supplyAndInstallKitPrice}</span>{' '}
              <span className="vip">VIP</span>
            </div>
          </div>
        )}

        {/* <div className="button-wrp ves-transition"> */}
        {/* use {bgColorClass(bgColor)} method to get button bg color as done above and remove this comment */}
        {/* <span className="btn package-btn"> */}
        {/* <Link to={requestQuoteLink}>Request a quote</Link> */}
        {/* </span> */}
        {/* </div> */}

        <Link to={requestQuoteLink} div className="button-wrp ves-transition">
          {/* use {bgColorClass(bgColor)} method to get button bg color as done above and remove this comment */}
          <span className="btn package-btn">Request a quote</span>
        </Link>

        {/* <div className="compare-link hidden">
          <p>
            <button type="button" href={comparePackagesLink} onClick={() => flickityResizeCall()}>
              Compare Packages
            </button>
          </p>
        </div> */}
      </div>

      <div className="middle-content ves-transition">
        <div className="content-wrp">
          <ul>
            {packageDetails?.map((item) => (
              <li key={uuidv4()}>
                <span>{item.title}</span>
                {item.description}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default function Packages({ sectionTitle, sectionDescription, packagesDetails }) {
  // const [flickityOnclick, setflickityOnclick] = useState(false);

  // console.log(flickityOnclick);

  // const flickityRef = useRef(null);

  // flickityRef.current.on('ready', () => {
  //   flickityRef.current.resize();
  // });
  return (
    <>
      <PackageWrpStyled className="packages-section-main ves-section" id="packages-section">
        <div className="packages-section-wrp ves-container">
          <div className="packages-content-wrp">
            {sectionTitle && <h2 className="h2">{sectionTitle}</h2>}
            {sectionDescription && <div className="tagline">{sectionDescription}</div>}

            <div className="packages-item-wrp">
              <Flickity
                className="carousel-nav" // default ''
                elementType="div" // default 'div'
                options={flickityOptions} // takes flickity options {}
                disableImagesLoaded={false} // default false
                reloadOnUpdate // default false
                // eslint-disable-next-line no-return-assign
                // flickityRef={(c) => (flickityRef.current = c)}
              >
                {packagesDetails.map((item) => (
                  <PackagesItem
                    key={uuidv4()}
                    capacity={item?.capacity}
                    packageName={item?.packageName}
                    diyKitPrice={item?.diyKitPrice}
                    supplyAndInstallKitPrice={item?.supplyAndInstallKitPrice}
                    packageDetails={item?.packageDetailsRepeaterContent}
                    bgColor={item?.themeColor}
                    requestQuoteLink={item?.buttonLink.url}
                    comparePackagesLink="#"
                    // flickityResize={() => setflickityOnclick(true)}
                  />
                ))}
              </Flickity>
            </div>
          </div>
        </div>
      </PackageWrpStyled>
    </>
  );
}

Packages.propTypes = {
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
};

PackagesItem.propTypes = {
  bgColor: PropTypes.string,
  diyKitPrice: PropTypes.string,
  supplyAndInstallKitPrice: PropTypes.string,
  requestQuoteLink: PropTypes.string,
  packageName: PropTypes.string,
  capacity: PropTypes.string,
};
