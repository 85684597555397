import tw, { styled } from 'twin.macro';

export const FloatingCardGridStyled = styled.section`
  ${tw`block relative bg-gray-lighter`};
  ${(props) => {
    if (props.colored === true) {
      return tw`bg-white`;
    }
  }}

  .grid-container {
    ${tw`relative`};

    &::after {
      content: '';
      ${tw`bg-white rounded-md absolute w-full h-full block`};
      box-shadow: 0px 5px 15px #0000001a;
      z-index: 0;
      background-color: #efefef;
    }
  }

  .constrain {
    ${tw`mx-auto`}
    max-width: 1007px;
  }

  .section-link {
    ${tw`mt-12 md:mt-16 text-center`}

    .more {
      ${tw`flex self-start leading-3 tracking-widest uppercase font-bold relative flex items-center border-b-2 border-transparent group-hover:border-b-2 group-hover:border-primary-dark inline-flex transition transition-all ease-in-out duration-200`}
      &::after {
        content: '';
        display: block;
        background-image: url('data:image/svg+xml,%3Csvg aria-hidden=%27true%27 data-prefix=%27fas%27 data-icon=%27arrow-right%27 xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 448 512%27 class=%27svg-inline--fa fa-arrow-right fa-w-14 fa-2x%27%3E%3Cpath fill=%27%230085D3%27 d=%27M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z%27/%3E%3C/svg%3E');
        width: 12px;
        height: 12px;
        background-repeat: no-repeat;
        margin-left: 8px;
        ${(props) => {
          if (props.dark === true) {
            return "background-image: url('data:image/svg+xml,%3Csvg aria-hidden=%27true%27 data-prefix=%27fas%27 data-icon=%27arrow-right%27 xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 448 512%27 class=%27svg-inline--fa fa-arrow-right fa-w-14 fa-2x%27%3E%3Cpath fill=%27%23000D3F%27 d=%27M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z%27/%3E%3C/svg%3E');";
          }
        }}
      }
    }
  }
`;

export const SectionHeaderStyled = styled.div`
  ${tw`flex flex-col relative items-center text-center`};

  img {
    ${tw`mb-5 md:mb-9`}
  }

  h2 {
    ${tw`mb-3 text-primary`}

    &.text-primary-dark {
      ${tw`text-primary-dark`}
    }
  }

  h4,
  strong {
    ${tw`font-bold mb-12 md:mb-16 leading-8 text-primary-dark ml-auto mr-auto text-xl`}
    max-width: 572px;
  }

  p {
    ${tw`mb-12 md:mb-5 leading-8 text-gray`}
  }

  .gatsby-image-wrapper {
    ${tw`mb-4`}
  }

  .tagline {
    ${tw`ml-auto mr-auto`}
    max-width: 750px;
  }
`;
