import tw, { styled } from 'twin.macro';

export const FloatingCardStyled = styled.div`
  ${tw`flex flex-col items-center p-5 relative bg-white`};
  z-index: 1;
  margin: 0 0 1px 1px;

  &::after {
    content: '';
    z-index: 0;
    ${tw`flex absolute group-hover:bg-primary w-full h-full transition transition-all ease-in-out duration-200 top-0 scale-100 shadow-none`};
  }

  /*&::before {
    content: '';
    z-index: -1;
    ${tw`border border-gray-light border-r-0 border-t-0 w-full h-full flex absolute top-0`};
  }*/

  .content {
    ${tw`flex flex-col items-center z-10 py-10 pb-2`};
  }

  img {
    ${tw`transition transition-all ease-in-out duration-200`};
  }

  h6 {
    ${tw`text-primary transition transition-all ease-in-out duration-200 relative font-bold mb-5 mt-2 transform scale-90 text-lg text-center`};
    ${(props) => {
      if (props.dark === true) {
        return tw`text-primary-dark`;
      }
      return tw`text-primary`;
    }}
  }

  p {
    ${tw`text-gray text-sm text-center group-hover:text-white transition transition-all ease-in-out duration-200 relative transform md:scale-100`};
  }

  .more {
    ${tw`leading-3 text-white tracking-widest uppercase font-bold relative flex items-center text-xs transition transition-all ease-in-out duration-200 opacity-0 mt-0 mb-4`}

    &::after {
      content: '';
      display: block;
      background-image: url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2711.05%27 height=%2710.77%27 viewBox=%270 0 11.05 10.77%27%3E%3Cpath id=%27Icon_awesome-arrow-right%27 data-name=%27Icon awesome-arrow-right%27 d=%27M4.7,3.369l.547-.547a.589.589,0,0,1,.836,0l4.794,4.792a.589.589,0,0,1,0,.836L6.082,13.243a.589.589,0,0,1-.836,0L4.7,12.7a.593.593,0,0,1,.01-.846L7.68,9.019H.592A.59.59,0,0,1,0,8.427V7.638a.59.59,0,0,1,.592-.592H7.68L4.708,4.215A.588.588,0,0,1,4.7,3.369Z%27 transform=%27translate%280 -2.647%29%27 fill=%27%23fff%27/%3E%3C/svg%3E%0A');
      width: 12px;
      height: 12px;
      background-repeat: no-repeat;
      margin-left: 8px;
    }
  }

  &:hover {
    ${tw`z-10`};

    &::after {
      ${tw`bg-primary rounded-md`};
      content: '';
      width: calc(100% + 20px);
      height: calc(100% + 30px);
      left: -10px;
      top: -15px;
      ${(props) => {
        if (props.dark === true) {
          return tw`bg-primary-dark`;
        }
        return tw`bg-primary`;
      }}
      box-shadow: 10px 40px 50px #000D3F33;
    }

    img {
      filter: brightness(0) invert(1);
    }

    h6 {
      ${tw`text-white scale-100`};
    }

    p {
      ${tw`text-white`};
    }

    .more {
      ${tw`opacity-100 mt-4 mb-0`}
    }
  }
`;
