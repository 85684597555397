import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import Forms from '../Forms/form';

import { FCFWrpStyled } from './fcpcontactform.styled';

export default function FCPContactForm({ title, subTitle, description, backgroundImage }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();
  const contactFormId = '758';

  const onSubmit = async (values, e) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('nameField', values.name);
      formData.append('email', values.email);
      formData.append('phone', values.telephone);
      // formData.append('roofSize', values.roofSize);
      formData.append('district', values.district);
      formData.append('village', values.village);
      formData.append('energy-solution', values.energySolution);
      formData.append('efl-connection', values.efl);
      formData.append('reason-for-contact', values.reasonOfContact);
      formData.append('average-daily-electricity-usage', values.averageDaily);
      formData.append('message', values.message);

      const { data } = await axios.post(
        `${process.env.WPREST_URL}/contact-form-7/v1/contact-forms/${contactFormId}/feedback/`,
        formData,
      );

      setStatus({
        type: data.status.split('_')[1],
        message: data.message,
      });

      e.target.reset();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      reset();
    }
  };

  return (
    <>
      <FCFWrpStyled backgroundImage={backgroundImage} className="fcp-contact-form-section">
        <div className="fcf-outer-wrp ves-container">
          <div className="fcf-inner-wrp">
            <div className="content-section-wrp">
              {subTitle && <div className="tagline">{subTitle}</div>}
              {title && <h2 className="h2">{title}</h2>}
              {description && (
                // eslint-disable-next-line react/no-danger
                <div className="content" dangerouslySetInnerHTML={{ __html: description }} />
              )}
            </div>
            <div className="form-section-wrp">
              <Forms>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-field-container">
                    <div className="form-row">
                      <label className="block" htmlFor="name">
                        <span className="label-span text-gray-dark">Name</span>
                        {/* eslint-disable react/jsx-props-no-spreading */}
                        <input
                          type="text"
                          className="form-input mt-1 block w-full"
                          id="name"
                          name="name"
                          {...register('name', { required: true })}
                        />
                        <span className="field-error">
                          {errors.name?.type === 'required' && '*Name is required'}
                        </span>
                      </label>
                    </div>

                    <div className="form-row">
                      <label className="block" htmlFor="email">
                        <span className="label-span text-gray-dark">Email Address</span>
                        <input
                          type="email"
                          className="form-input mt-1 block w-full"
                          id="email"
                          name="email"
                          {...register('email', { required: true })}
                        />
                        <span className="field-error">
                          {errors.email?.type === 'required' && '*Email is required'}
                        </span>
                      </label>
                    </div>

                    <div className="form-row">
                      <label className="block" htmlFor="telephone">
                        <span className="label-span text-gray-dark">Phone number</span>
                        <input
                          type="tel"
                          className="form-input mt-1 block w-full"
                          id="telephone"
                          name="telephone"
                          {...register('telephone', {
                            required: true,
                            pattern: /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                          })}
                        />
                        <span className="field-error">
                          {errors.telephone?.type === 'required' && '*Telephone is required'}
                          {errors.telephone?.type === 'pattern' && '*Phone number is invalid'}
                        </span>
                      </label>
                    </div>

                    {/* <div className="form-row">
                      <label className="block" htmlFor="number">
                        <span className="label-span text-gray-dark">Sq. Ft. Size of the roof</span>
                        <input
                          type="number"
                          className="form-input mt-1 block w-full"
                          id="roofSize"
                          name="roofSize"
                          min="1"
                          {...register('roofSize', { required: true })}
                        />
                        <span className="field-error">
                          {errors.roofSize?.type === 'required' && '*This field is required'}
                        </span>
                      </label>
                    </div> */}

                    <div className="form-row">
                      <label className="block" htmlFor="select">
                        <span className="label-span text-gray-dark">District</span>
                        <select
                          className="form-select block w-full mt-1"
                          id="district"
                          name="district"
                          {...register('district')}>
                          <option value="Ba">Ba</option>
                          <option value="Bua">Bua</option>
                          <option value="Cakaudrove">Cakaudrove</option>
                          <option value="Kadavu">Kadavu</option>
                          <option value="Lau">Lau</option>
                          <option value="Lomaiviti">Lomaiviti</option>
                          <option value="Macuata">Macuata</option>
                          <option value="Nadroga-Navosa">Nadroga-Navosa</option>
                          <option value="Naitasiri">Naitasiri</option>
                          <option value="Namosi">Namosi</option>
                          <option value="Ra">Ra</option>
                          <option value="Rewa">Rewa</option>
                          <option value="Serua">Serua</option>
                          <option value="Tailevu">Tailevu</option>
                          <option value="Rotuma">Rotuma</option>
                        </select>
                      </label>
                    </div>

                    <div className="form-row">
                      <label className="block" htmlFor="village">
                        <span className="label-span text-gray-dark">Village</span>
                        <input
                          type="text"
                          className="form-input mt-1 block w-full"
                          id="village"
                          name="village"
                          {...register('village')}
                        />
                      </label>
                    </div>

                    <div className="form-row">
                      <label className="block" htmlFor="energySolution">
                        <span className="label-span text-gray-dark">Energy Solution For</span>
                        <input
                          type="text"
                          className="form-input mt-1 block w-full"
                          id="energySolution"
                          name="energySolution"
                          {...register('energySolution')}
                        />
                      </label>
                    </div>

                    <div className="form-row">
                      <label className="block" htmlFor="select">
                        <span className="label-span text-gray-dark">
                          Do you have an EFL connection?
                        </span>
                        <select
                          className="form-select block w-full mt-1"
                          id="efl"
                          name="efl"
                          {...register('efl')}>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                          <option value="Pending">Pending</option>
                        </select>
                      </label>
                    </div>

                    <div className="form-row">
                      <label className="block" htmlFor="reasonOfContact">
                        <span className="label-span text-gray-dark">Reason for your contact</span>
                        <input
                          type="text"
                          className="form-input mt-1 block w-full"
                          id="reasonOfContact"
                          name="reasonOfContact"
                          {...register('reasonOfContact')}
                        />
                      </label>
                    </div>

                    <div className="form-row">
                      <label className="block" htmlFor="averageDaily">
                        <span className="label-span text-gray-dark">
                          Average Daily Electricity usage (Wh/Day)
                        </span>
                        <input
                          type="text"
                          className="form-input mt-1 block w-full"
                          id="averageDaily"
                          name="averageDaily"
                          {...register('averageDaily')}
                        />
                      </label>
                    </div>

                    <div className="form-row">
                      <label className="block" htmlFor="textarea">
                        <span className="label-span text-gray-dark">Message</span>
                        <textarea
                          className="form-textarea mt-1 block w-full"
                          rows="3"
                          id="message"
                          name="message"
                          {...register('message')}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="form-row submit-button-section">
                    {/* Change this to button tag to add a disable state */}
                    {/* <input
                      type="submit"
                      value="Send"
                      className="btn-cta submit"
                      disabled={loading}
                    /> */}
                    <div className="button-wrp">
                      <button type="submit" className="btn-cta submit" disabled={loading}>
                        Send
                      </button>
                    </div>
                    {status && (
                      <div className={`form-feedback status-${status.type}`}>{status.message}</div>
                    )}
                  </div>
                </form>
              </Forms>
            </div>
          </div>
        </div>
      </FCFWrpStyled>
    </>
  );
}

FCPContactForm.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
};
