import tw, { styled } from 'twin.macro';
// eslint-disable-next-line import/no-extraneous-dependencies
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const PaymentMethodsStyled = styled.section`
  .solutions-inner-wrp {
    ${tw`flex flex-col md:flex-row md:flex-row-reverse`}

    .solution-content-wrp {
      ${tw`text-center md:text-left pb-16 md:pb-0`}

      .solar-energy {
        ${tw`mb-12 inline-block md:block`}
        fill: #0085d3;
      }

      h2 {
        ${tw`mb-5 text-gray-darker`}
      }

      .tagline {
        ${tw`font-bold mb-5 leading-7`}
      }

      .content {
        ${tw`mb-7 leading-7 text-gray-darker`}
      }

      .contact-us {
        max-width: 320px;
        margin: 0 auto;

        @media (min-width: ${fullConfig.theme.screens.md}) {
          max-width: 180px;
          margin: 0;
        }
      }
    }

    .solution-type-cards-wrp {
      ${tw`col-span-2 grid grid-cols-1 md:grid-cols-2 md:gap-4 lg:gap-x-12`}

      @media (min-width: ${fullConfig.theme.screens.md}) {
        ${tw`items-center`}
        padding-right: 90px;
        max-width: 680px;
        width: 100%;
      }

      @media (min-width: ${fullConfig.theme.screens.lg}) {
        padding-right: 114px;
      }

      .solution-card-item {
        ${tw`px-9 py-10 rounded-xl text-white mb-12 md:mb-0 flex flex-col justify-between`}
        min-height: 360px;

        @media (min-width: ${fullConfig.theme.screens.md}) {
          min-height: 275px;
          max-width: 260px;
          height: max-content;
        }

        @media (min-width: ${fullConfig.theme.screens.lg}) {
          min-height: 360px;
        }

        .content-wrp {
          .card-item-image {
            ${tw`mb-8 md:mb-3 lg:mb-9`}
          }

          h3 {
            ${tw`mb-5`}
          }

          .content {
            ${tw`mb-5 md:hidden lg:block`}
          }
        }

        .learn-more {
          ${tw`text-xs leading-3 tracking-widest uppercase font-bold pr-5`}
          position: relative;
          z-index: 1;

          &::after {
            content: '';
            position: absolute;
            top: 1px;
            right: 0;
            z-index: 1;
            background-image: url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2711.05%27 height=%2710.77%27 viewBox=%270 0 11.05 10.77%27%3E%3Cpath id=%27Icon_awesome-arrow-right%27 data-name=%27Icon awesome-arrow-right%27 d=%27M4.7,3.369l.547-.547a.589.589,0,0,1,.836,0l4.794,4.792a.589.589,0,0,1,0,.836L6.082,13.243a.589.589,0,0,1-.836,0L4.7,12.7a.593.593,0,0,1,.01-.846L7.68,9.019H.592A.59.59,0,0,1,0,8.427V7.638a.59.59,0,0,1,.592-.592H7.68L4.708,4.215A.588.588,0,0,1,4.7,3.369Z%27 transform=%27translate%280 -2.647%29%27 fill=%27%23fff%27/%3E%3C/svg%3E%0A');
            width: 12px;
            height: 12px;
            background-repeat: no-repeat;
          }
        }
      }
    }

    .conditions {
      ${tw`w-full`}

      h6 {
        ${tw`text-base font-bold text-gray-darker mb-2`}
      }

      p {
        ${tw`text-xs text-gray font-normal mb-4`}
      }
    }
  }
`;
