import tw, { styled } from 'twin.macro';

export const BreadcrumbStyled = styled.div`
  ${tw`text-base flex-col flex justify-center items-center border-b`};
  ${tw`bg-white`};
  border-color: #e2e2e2;

  .ves-container {
    ${tw`flex items-center justify-start w-full text-sm md:text-base py-3 pr-0 relative`};

    /*&:after {
      content: '';
      width: 60px;
      ${tw`flex absolute bg-gradient-to-r from-transparent to-white h-full right-0`};
    }*/

    a,
    span {
      ${tw`text-sm md:text-base leading-5 md:leading-7 mr-5 text-primary-dark hover:text-primary transition transition-all ease-in-out duration-200`};
    }

    span {
      ${tw`text-gray hover:text-gray`};
    }

    svg {
      ${tw`transform -rotate-90 w-2 mr-5`};
    }
  }
`;
