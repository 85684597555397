import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { TwoColWithIconsWrpStyled } from './twoColumnWithIcons.styled';

export default function TwoColumnWithIcons(props) {
  const { sectionTitle, sectionDescription, sectionImage, contentItems } = props;

  return (
    <TwoColWithIconsWrpStyled className="two-col-with-icons ves-section">
      <div className="ves-container">
        <div className="twocol-section-wrp">
          <div className="left-col">
            <GatsbyImage image={getImage(sectionImage)} alt="Two column with icons section" />
          </div>

          <div className="right-col">
            <div className="title-wrp">
              {sectionTitle && <h2>{sectionTitle}</h2>}
              {sectionDescription && <p>{sectionDescription}</p>}
            </div>

            <div className="content">
              {contentItems?.map((item) => (
                <div key={uuidv4()} className="content-col">
                  {item.icon?.localFile?.publicURL && (
                    <div className="icon">
                      <img src={item.icon.localFile.publicURL} alt={item.title} />
                    </div>
                  )}
                  {item.title && <h4>{item.title}</h4>}
                  {item.description && <p>{item.description}</p>}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </TwoColWithIconsWrpStyled>
  );
}

TwoColumnWithIcons.propTypes = {
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
  sectionImage: PropTypes.shape({
    sectionImage: PropTypes.shape({}),
  }),
  contentItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
};
