import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import PropTypes from 'prop-types';

import { GalleryWrpStyled } from './gallery.styled';

export default function Gallery({ image, video, className }) {
  const source = video || image;
  const [toggler, setToggler] = useState(false);

  return (
    <>
      <GalleryWrpStyled className={className}>
        <div className="video-section-wrp">
          <img src={image} alt="" />
        </div>
        <div
          className={`video-section-overlay-wrp ${video ? 'has-video' : ''}`}
          onClick={() => setToggler(!toggler)}
          aria-hidden="true">
          <div className="button-wrp">
            <span className="play-icon" />
            <span className="watch-video">Watch the video</span>
          </div>
          <FsLightbox toggler={toggler} sources={[source]} />
        </div>
      </GalleryWrpStyled>
    </>
  );
}

Gallery.propTypes = {
  image: PropTypes.string.isRequired,
  video: PropTypes.string,
  className: PropTypes.string,
};
