import tw, { styled } from 'twin.macro';
// eslint-disable-next-line import/no-extraneous-dependencies
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const GalleryWrpStyled = styled.div`
  ${tw`relative`}
  z-index: 1;

  .video-section-wrp {
    ${tw`relative`}
    z-index: 1;

    img {
      ${tw`w-full`}
    }
  }

  .video-section-overlay-wrp {
    ${tw`absolute top-0 left-0 w-full h-full cursor-pointer`}

    z-index: 1;

    .button-wrp {
      ${tw`hidden`}
    }

    &.has-video {
      &::after {
        ${tw`absolute top-0 left-0 w-full h-full block opacity-40 bg-primary`}

        content: '';
        z-index: -1;
      }

      .button-wrp {
        ${tw`absolute top-1/2 left-1/2 text-center block`}

        transform: translate(-50%,-50%);
        z-index: 1;

        .play-icon {
          ${tw`relative inline-block mb-1 md:mb-12`}

          width: 20px;
          height: 24px;

          @media (min-width: ${fullConfig.theme.screens.md}) {
            width: 74px;
            height: 87px;
          }

          &::after {
            ${tw`w-full h-full block`}

            content: '';
            background-image: url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2774%27 height=%2787%27 viewBox=%270 0 74 87%27%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cpath class=%27a%27 d=%27M43.5,0,87,74H0Z%27 transform=%27translate%2874%29 rotate%2890%29%27/%3E%3C/svg%3E');
            background-size: contain;
            background-repeat: no-repeat;
          }
        }

        .watch-video {
          font-size: 10px;
          ${tw`md:text-xs tracking-wider uppercase bg-white rounded-xl block font-bold py-2 px-3 leading-none text-primary-dark`}
        }
      }
    }
  }
`;
