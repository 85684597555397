import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import Gallery from '../gallery';

import { TwoColWrpStyled } from './twocolumn.styled';

export default function TwoColumn({ twoColContent }) {
  return (
    <>
      {twoColContent?.map((item, index) => (
        <TwoColWrpStyled key={uuidv4()} className="twocol-section">
          <div className={`twocol-section-wrp ${index % 2 === 0 ? '' : 'image-left'}`}>
            <div className="twocol-content-wrp">
              <div className="content-inner-wrp">
                <h2 className="h2">{item.title}</h2>
                {/* eslint-disable react/no-danger */}
                <div className="content" dangerouslySetInnerHTML={{ __html: item.textContent }} />
                {item.ctaLink && (
                  <div className="btn-wrp">
                    <a href={item.ctaLink.url} className="btn-primary">
                      {item.ctaLink.title}
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className={`twocol-image-wrp ${item.imageOption ? 'image-contain' : ''}`}>
              {item.videoUrl ? (
                <Gallery
                  image={item.image.localFile.publicURL}
                  video={item.videoUrl}
                  className="h-full"
                />
              ) : (
                <div className="image-inner-wrp">
                  <img src={item.image.localFile.publicURL} alt="Twocol" />
                </div>
              )}
            </div>
          </div>
        </TwoColWrpStyled>
      ))}
    </>
  );
}

TwoColumn.propTypes = {
  twoColContent: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      textContent: PropTypes.string,
    }),
  ),
};
