import React from 'react';
import Flickity from 'react-flickity-component';
import { v4 as uuidv4 } from 'uuid';

import {
  FloatingNumberedCardSliderStyled,
  SectionHeaderStyled,
  FloatingNumberedCardStyled,
} from './floating-numbered-card-slider.styled';

const flickityOptions = {
  autoplay: true,
  pauseAutoPlayOnHover: false,
  cellAlign: 'center',
  freeScroll: true,
  contain: true,
  prevNextButtons: false,
};

export function FloatingNumberedCard({ index, content, icon }) {
  return (
    <FloatingNumberedCardStyled className="group">
      <div className="content">
        <div className="flex items-center justify-between w-full title-block">
          <h6>{index}</h6>
          <img src={icon} alt="icon" />
        </div>
        <p className="p">{content}</p>
      </div>
    </FloatingNumberedCardStyled>
  );
}

export function FloatingNumberedCardSlider({ sectionTitle, description, floatingCardsContent }) {
  return (
    <FloatingNumberedCardSliderStyled className="ves-section">
      <div className={`ves-container ${floatingCardsContent?.length <= 4 ? 'constrained' : ''}`}>
        <SectionHeaderStyled>
          <h2 className="h2">{sectionTitle}</h2>
          {/* <h4 className="h4 tagline">
            Before making a capital investment, it is important that all decision-makers have
            complete and accurate information.
          </h4>
          <p>
            Our solar team is composed of qualified engineers, project managers, and financing
            specialists. We will partner with your leadership team to do a thorough project analysis
            and present our recommendations.
          </p> */}
          <div className="content" dangerouslySetInnerHTML={{ __html: description }} />
        </SectionHeaderStyled>

        <Flickity
          className="floating-carousel" // default ''
          elementType="div" // default 'div'
          options={flickityOptions} // takes flickity options {}
          disableImagesLoaded={false} // default false
          reloadOnUpdate // default false
        >
          {floatingCardsContent?.map((item, index) => (
            <FloatingNumberedCard
              key={uuidv4()}
              index={index + 1}
              content={item.title}
              icon={item.icon.localFile.publicURL}
            />
          ))}
        </Flickity>
      </div>
    </FloatingNumberedCardSliderStyled>
  );
}

export default FloatingNumberedCardSlider;
