import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { CSWrpStyled } from './contentsection.styled';

export function ContentSectionItem({ itemTitle, itemContent, themeColor, icon }) {
  return (
    <div className="about-content-item">
      {icon && (
        <div className={`image-wrp bg-${themeColor}`}>
          <img src={icon} alt="icon" />
        </div>
      )}
      {itemTitle && <h4 className={`h4 text-${themeColor}`}>{itemTitle}</h4>}
      {itemContent && <div className="content">{itemContent}</div>}
    </div>
  );
}

function ContentItemBgClass(color) {
  switch (color) {
    case 'green':
      return 'success';
    case 'blue':
      return 'primary';
    case 'orange':
      return 'secondary-orange';
    default:
      return 'primary';
  }
}

export default function ContentSection({
  sectionTitle,
  sectionIcon,
  contentItems,
  backgroundColor,
  titleWidth,
}) {
  return (
    <>
      <CSWrpStyled>
        <div className={`cs-bg-wrp ${backgroundColor ? 'white' : 'gray'}`}>
          <div className="cs-inner-wrp ves-container">
            <div className="description-wrp">
              <div className="image-wrp">
                {sectionIcon && <img src={sectionIcon} alt="section-icon" />}
              </div>
              {sectionTitle && (
                <h2 className={`h2 ${titleWidth ? 'narrow' : 'wide'}`}>{sectionTitle}</h2>
              )}
            </div>
            <div
              className={`cs-section-wrp ${
                contentItems?.length === 4 ? 'four-columns' : 'three-columns'
              }`}>
              {contentItems?.map((item) => (
                <ContentSectionItem
                  key={uuidv4()}
                  themeColor={ContentItemBgClass(item?.primaryColor)}
                  itemTitle={item?.title}
                  itemContent={item?.description}
                  icon={item?.icon?.localFile.publicURL}
                />
              ))}
            </div>
          </div>
        </div>
      </CSWrpStyled>
    </>
  );
}

ContentSectionItem.propTypes = {
  itemTitle: PropTypes.string.isRequired,
  itemContent: PropTypes.string.isRequired,
  themeColor: PropTypes.string.isRequired,
};
