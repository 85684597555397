import tw, { styled } from 'twin.macro';
// eslint-disable-next-line import/no-extraneous-dependencies
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const TwoColWrpStyled = styled.div`
  ${tw`relative`}
  z-index: 2;

  .twocol-section-wrp {
    ${tw`grid grid-cols-1 lg:grid-cols-2 relative mx-auto items-center`}

    max-width: 1904px;

    .twocol-content-wrp {
      ${tw`relative pt-28 pb-10 px-9 lg:pr-0`}

      .content-inner-wrp {
        ${tw`mx-auto lg:ml-auto lg:mr-0 lg:pr-28`}
        max-width: 824px;

        @media (min-width: ${fullConfig.theme.screens.lg}) {
          max-width: 560px;
        }

        h2 {
          ${tw`text-primary-dark mb-7`}
        }

        .content {
          ${tw`text-gray-darker`}

          p {
            ${tw`mb-7 md:mb-9 md:text-lg leading-7 md:leading-9`}
          }

          a {
            ${tw`text-primary hover:underline`}
          }

          ul {
            li {
              ${tw`md:text-lg leading-7 md:leading-9 mb-5 relative pl-7`}

              &:before {
                ${tw`absolute left-0 block bg-primary top-2 md:top-3`}
                content: '';
                z-index: 1;
                width: 10px;
                height: 10px;
                border-radius: 50%;
              }
            }
          }
        }

        .btn-wrp {
          .btn-primary {
            ${tw`inline-block px-6`}
          }
        }
      }
    }

    .twocol-image-wrp {
      ${tw`relative h-full`}

      .video-section-wrp {
        ${tw`h-full`}

        img {
          ${tw`h-full object-cover`}
        }
      }

      .image-inner-wrp {
        ${tw`h-full`}

        img {
          ${tw`w-full h-full object-cover`}
        }
      }

      &.image-contain {
        .image-inner-wrp {
          img {
            ${tw`object-contain`}
          }
        }
      }
    }

    &.image-left {
      .twocol-content-wrp {
        ${tw`lg:order-2 lg:pr-9 lg:pl-0`}

        .content-inner-wrp {
          ${tw`lg:ml-0 lg:pl-24 lg:pr-0`}
        }
      }

      .twocol-image-wrp {
        ${tw`lg:order-1`}
      }
    }
  }
`;
