import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import ProjectGallery from '../projectGallery';

import { ProjectGalleryComponantWrpStyled } from './projectGalleryComponant.styled';

export default function ProjectGalleryComponant({
  sectionTitle,
  sectionDescription,
  contentItems,
}) {
  return (
    <ProjectGalleryComponantWrpStyled className="gallery-section ves-section">
      <div className="gallery-section-wrp ves-container">
        {sectionTitle && <h2 className="section-title">{sectionTitle}</h2>}
        {sectionDescription && <p className="section-description">{sectionDescription}</p>}
        {contentItems?.map((galleryItem) => (
          <ProjectGallery
            key={uuid()}
            title={galleryItem?.projectName}
            description={galleryItem?.description}
            images={galleryItem?.images}
          />
        ))}
      </div>
    </ProjectGalleryComponantWrpStyled>
  );
}

ProjectGalleryComponant.propTypes = {
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
  contentItems: PropTypes.arrayOf(PropTypes.any),
};
