import tw, { styled } from 'twin.macro';
// eslint-disable-next-line import/no-extraneous-dependencies
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const FCFWrpStyled = styled.div`
  ${tw`bg-primary pt-36 pb-80 md:pb-64 lg:pb-40 xl:pb-24 relative`}
  z-index: 1;

  &::after {
    ${tw`absolute bottom-0 right-0`}
    content: '';
    background-image: url(${(props) => props.backgroundImage});
    background-size: cover;
    background-repeat: no-repeat;
    width: 340px;
    height: 300px;
    z-index: -1;

    @media (min-width: ${fullConfig.theme.screens.md}) {
      width: 350px;
      height: 360px;
    }

    @media (min-width: ${fullConfig.theme.screens.xl}) {
      width: 602px;
      height: 552px;
    }
  }

  .fcf-inner-wrp {
    ${tw`grid grid-cols-1 lg:grid-cols-3 lg:gap-6 relative`}

    @media (min-width: ${fullConfig.theme.screens.md}) {
      max-width: 684px;
      ${tw`mx-auto`}
    }

    @media (min-width: ${fullConfig.theme.screens.lg}) {
      max-width: 100%;
    }

    .content-section-wrp {
      ${tw`justify-center flex flex-col text-white text-center lg:text-left`}

      .tagline {
        ${tw`uppercase font-bold mb-2`}
      }

      h2 {
        ${tw`mb-12 mx-auto lg:mx-0 lg:mb-2`}

        max-width: 545px;
      }

      .content ul {
        ${tw`mx-auto mb-10 md:flex md:justify-between lg:block`}

        max-width: 165px;

        @media (min-width: ${fullConfig.theme.screens.md}) {
          max-width: 100%;
        }

        li {
          ${tw`text-xl text-left font-bold relative pl-6 md:pl-10 lg:pl-7 mb-2`}

          &::after {
            ${tw`absolute top-2 left-0 w-5 h-5`}

            content: '';
            background-image: url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712.459%27 height=%2712.15%27 viewBox=%270 0 12.459 12.15%27%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cpath class=%27a%27 d=%27M94.855,16.529a1.326,1.326,0,0,1-1.006-.463l-3.181-3.711a1.326,1.326,0,0,1,2.013-1.725l2.079,2.426,6.572-7.925c1.017-1.425,2.082-.636,1.06.8L95.933,15.974a1.326,1.326,0,0,1-1.02.554Z%27 transform=%27translate%28-90.349 -4.379%29%27/%3E%3C/svg%3E');
            background-repeat: no-repeat;
            z-index: 1;
          }
        }
      }
    }

    .form-section-wrp {
      ${tw`col-span-2`}

      .form-wrp {
        ${tw`lg:pr-6 lg:pl-20`}
      }

      .form-field-container {
        ${tw`md:grid md:grid-cols-2 md:gap-x-6`}

        .label-span {
          ${tw`text-white`}
        }
      }

      .submit {
        ${tw`w-full mt-12 mx-auto md:mx-0`}

        max-width: 320px;

        @media (min-width: ${fullConfig.theme.screens.md}) {
          max-width: 180px;
        }
      }
    }

    @media (min-width: 1280px) and (max-width: 1579px) {
      ${tw`grid-cols-1`}

      .form-section-wrp {
        .form-wrp {
          ${tw`px-0`}
          max-width: 900px;

          .form-field-container {
            ${tw`grid grid-cols-3 gap-x-6`}
          }
        }
      }
    }

    .form-textarea {
      ${tw`h-12`}
    }
  }
`;
