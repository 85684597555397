import tw, { styled } from 'twin.macro';
// eslint-disable-next-line import/no-extraneous-dependencies
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const ProductsListStyled = styled.section`
  ${tw`block relative`};

  .product-list {
    @media (max-width: 767px) {
      max-width: 360px;
      ${tw`mx-auto`};
    }

    .product-item:nth-child(-n + 3) {
      @media (min-width: ${fullConfig.theme.screens.md}) {
        ${tw`mb-5`};
      }

      @media (min-width: ${fullConfig.theme.screens.lg}) {
        ${tw`mb-11`};
      }
    }
  }
`;

export const SectionHeaderStyled = styled.div`
  ${tw`flex flex-col relative items-center text-center`};

  h2 {
    ${tw`mb-8 text-primary-dark`}
  }
`;
