import tw, { styled } from 'twin.macro';

export const FeatureStyled = styled.div`
  ${tw`flex flex-col items-center px-6 relative w-full`};
  z-index: 1;

  .content {
    ${tw`flex flex-col items-center z-10 mb-16 lg:mb-0`};
  }

  p {
    ${tw`text-gray-darker font-bold text-base relative transform leading-6 text-center`};
    margin-top: 15px;
  }

  .gatsby-image-wrapper {
    ${tw`mt-1`};
    max-width: 122px;
    max-height: 122px;
  }
`;

export const FeaturesStyled = styled.section`
  ${tw`block relative overflow-x-hidden pt-5 md:pt-11 md:pb-6 lg:pt-12 lg:pb-9`};

  .grid-container {
    ${tw`relative`};
  }
`;

export const SectionHeaderStyled = styled.div`
  ${tw`flex flex-col relative items-center text-center pb-12 md:pb-14 mx-auto`};
  max-width: 768px;

  h2 {
    ${tw`mb-3 text-primary-dark`};
  }

  .tagline {
    ${tw`font-bold leading-8 text-primary-dark`};
  }

  p {
    ${tw`text-gray`};
  }

  .gatsby-image-wrapper {
    ${tw`mb-4`};
  }
`;
