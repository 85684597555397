/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../layouts/layout';
import CommonBanner from '../components/commonBanner';
import CTA from '../components/CTA';
import TabbedContent from '../components/tabbed-content';
import Partners from '../components/Partners';
import FloatingCardGrid from '../components/floating-card-grid';
import FloatingNumberedCardSlider from '../components/floating-numberd-card-slider';
import TwoColumn from '../components/TwoColumns';
import Products from '../components/products';
import Features from '../components/features';
import PaymentMethods from '../components/paymentMethods';
import GalleryComponant from '../components/galleryComponant';
import Packages from '../components/Packages';
import BasicContent from '../components/BasicContent';
import Breadcrumb from '../components/breadcrumb';
import FCPContactForm from '../components/fcpContactForm';
import ContentSection from '../components/ContentSection';
import TwoColumnWithIcons from '../components/twoColumnWithIcons';
import ProjectGallery from '../components/projectGalleryComponant';
import MetaTags from '../components/SiteMeta';

export default function Page({ data }) {
  const { acfGlobalContent } = data?.page?.nodes[0];
  const { flexibleContent } = data?.page?.nodes[0].acfUniversalContent;
  const { acfUniversalContent } = data?.page?.nodes[0];

  // Breadcrumbs
  let breadcrumbsData = [];
  let parentElement = data?.wpPage;
  while (parentElement) {
    breadcrumbsData.push({
      title: parentElement?.title ? parentElement.title : null,
      url: parentElement?.uri,
    });
    parentElement = parentElement?.wpParen;
  }

  breadcrumbsData = breadcrumbsData.reverse();

  return (
    <>
      <Layout preFooterContent={acfGlobalContent}>
        <MetaTags
          title={acfGlobalContent.metaTitle}
          description={acfGlobalContent.metaDescription}
          image={acfGlobalContent.metaOgImage?.sourceUrl}
        />
        <CommonBanner
          title={acfUniversalContent?.bannerTitle}
          subtitle={acfUniversalContent?.bannerSubtitle}
          banner={acfUniversalContent?.bannerImage?.localFile}
        />

        {acfUniversalContent?.showBreadcrumbs && <Breadcrumb data={breadcrumbsData} />}

        {flexibleContent?.length > 0 ? (
          flexibleContent.map((content) => {
            const renderSections = (fieldGroupName) => {
              switch (fieldGroupName) {
                case 'page_Acfuniversalcontent_FlexibleContent_Cta': {
                  return (
                    <React.Fragment key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}>
                      {typeof window !== 'undefined' && content?.showCtaSection && (
                        <CTA
                          key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}
                          ctaTitle={content?.ctaTitle}
                          ctaImage={content?.ctaFeaturedImage?.localFile}
                          ctaButtonText={content?.ctaButtonText}
                          ctaContent={content?.ctaDescription}
                          ctaBgColor={content?.ctaBackgroundColor}
                          imageRight={content?.ctaImagePosition}
                          ctaDownloadLink={content?.ctaDownloadLink}
                        />
                      )}
                    </React.Fragment>
                  );
                }

                case 'page_Acfuniversalcontent_FlexibleContent_TabbedNavigationSection': {
                  return (
                    <React.Fragment key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}>
                      {typeof window !== 'undefined' && (
                        <TabbedContent
                          key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}
                          sectionTitle={content?.sectionTitle}
                          tabsContent={content?.tabsContent}
                          featuredLogos={content?.featuredLogos}
                        />
                      )}
                    </React.Fragment>
                  );
                }

                case 'page_Acfuniversalcontent_FlexibleContent_PartnersSection': {
                  return (
                    // <div>partner</div>
                    <React.Fragment key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}>
                      {typeof window !== 'undefined' && (
                        <Partners
                          key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}
                          title={content?.partnersTitle}
                          subTitle={content?.partnersDescription}
                          logos={content?.partnersLogos}
                        />
                      )}
                    </React.Fragment>
                  );
                }

                case 'page_Acfuniversalcontent_FlexibleContent_FloatingCardsGrid': {
                  return (
                    <React.Fragment key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}>
                      {typeof window !== 'undefined' && (
                        <FloatingCardGrid
                          key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}
                          colCount={content?.floatingCardsNumberOfCards}
                          sectionTitle={content?.floatingCardsSectionTitle}
                          sectionDescription={content?.floatingCardsDescription}
                          featuredIcon={content?.floatingCardsFeaturedIcon?.localFile.publicURL}
                          primaryColor={content?.floatingCardsCardsPrimaryColor}
                          cards={content?.floatingCardsContent}
                          linkText={content?.floatingCardsLinkText}
                          sectionLink={content?.floatingCardsSectionLink}
                          sectionBackgroundColor={content?.floatingCardsSectionBackgroundColor}
                        />
                      )}
                    </React.Fragment>
                  );
                }

                case 'page_Acfuniversalcontent_FlexibleContent_NumberedFloatingCards': {
                  return (
                    <React.Fragment key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}>
                      {typeof window !== 'undefined' && (
                        <FloatingNumberedCardSlider
                          key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}
                          colCount="3"
                          sectionTitle={content?.sectionTitle}
                          description={content?.description}
                          floatingCardsContent={content?.floatingCardsContent}
                        />
                      )}
                    </React.Fragment>
                  );
                }

                case 'page_Acfuniversalcontent_FlexibleContent_TwoColumnContentGrid': {
                  return (
                    <React.Fragment key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}>
                      {typeof window !== 'undefined' && (
                        <TwoColumn
                          key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}
                          twoColContent={content?.content}
                        />
                      )}
                    </React.Fragment>
                  );
                }

                case 'page_Acfuniversalcontent_FlexibleContent_FeaturesSection': {
                  return (
                    <React.Fragment key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}>
                      {typeof window !== 'undefined' && (
                        <Features
                          key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}
                          sectionTitle={content?.featuresTitle}
                          sectionDescription={content?.featuresDescription}
                          logoContent={content?.featuresLogoContent}
                        />
                      )}
                    </React.Fragment>
                  );
                }

                case 'page_Acfuniversalcontent_FlexibleContent_Products': {
                  return (
                    <React.Fragment key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}>
                      {typeof window !== 'undefined' && (
                        <Products
                          key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}
                          sectionTitle={content?.sectionTitle}
                        />
                      )}
                    </React.Fragment>
                  );
                }

                case 'page_Acfuniversalcontent_FlexibleContent_PaymentMethods': {
                  return (
                    <React.Fragment key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}>
                      {typeof window !== 'undefined' && (
                        <PaymentMethods
                          key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}
                          sectionTitle={content?.paymentMethodsMainContent.title}
                          sectionDescription={content?.paymentMethodsMainContent.content}
                          sectionButtonLink={content?.paymentMethodsMainContent.buttonLink}
                          sectionIcon={content?.paymentMethodsMainContent.icon.localFile.publicURL}
                          sectionConditions={content?.paymentMethodsConditions}
                          cardContent={content?.paymentMethodsSpecialFeatures}
                        />
                      )}
                    </React.Fragment>
                  );
                }

                case 'page_Acfuniversalcontent_FlexibleContent_FullWidthGallery': {
                  return (
                    <React.Fragment key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}>
                      {typeof window !== 'undefined' && (
                        <GalleryComponant
                          key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}
                          title={content?.title}
                          image={content?.image.localFile.publicURL}
                          videoLink={content?.video}
                        />
                      )}
                    </React.Fragment>
                  );
                }

                case 'page_Acfuniversalcontent_FlexibleContent_Packages': {
                  return (
                    // <div>packages</div>
                    <React.Fragment key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}>
                      {typeof window !== 'undefined' && (
                        <Packages
                          key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}
                          sectionTitle={content?.packagesSectionTitle}
                          sectionDescription={content?.packagesDescription}
                          packagesDetails={content?.packagesDetails}
                        />
                      )}
                    </React.Fragment>
                  );
                }

                case 'page_Acfuniversalcontent_FlexibleContent_BasicContentSection': {
                  return (
                    <React.Fragment key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}>
                      {typeof window !== 'undefined' && (
                        <BasicContent
                          key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}
                          title={content?.basicContentSectionTitle}
                          description={content?.basicContentDescription}
                          twoCol={content?.basicContentOneColumnTwoColumns}
                          gray={content?.basicContentBackgroundColor}
                          basicContentEditorOne={content?.basicContentEditorOne}
                          basicContentEditorTwo={content?.basicContentEditorTwo}
                        />
                      )}
                    </React.Fragment>
                  );
                }

                case 'page_Acfuniversalcontent_FlexibleContent_ContactForm': {
                  return (
                    <React.Fragment key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}>
                      {typeof window !== 'undefined' && (
                        <FCPContactForm
                          key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}
                          title={content?.title}
                          subTitle={content?.tagline}
                          description={content?.description}
                          backgroundImage={content?.featuredImage?.localFile.publicURL}
                        />
                      )}
                    </React.Fragment>
                  );
                }

                case 'page_Acfuniversalcontent_FlexibleContent_ContentSectionWithIcons': {
                  return (
                    <React.Fragment key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}>
                      {typeof window !== 'undefined' && (
                        <ContentSection
                          key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}
                          sectionTitle={content?.contentSectionIconsSectionTitle}
                          backgroundColor={content?.contentSectionIconsBackgroundColor}
                          titleWidth={content?.contentSectionIconsSectionTitleWidth}
                          sectionIcon={
                            content?.contentSectionIconsSectionFeaturedIcon?.localFile.publicURL
                          }
                          contentItems={content?.contentSectionIconsContent}
                        />
                      )}
                    </React.Fragment>
                  );
                }

                case 'page_Acfuniversalcontent_FlexibleContent_TwoColumnContentSectionWithIcons': {
                  return (
                    <React.Fragment key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}>
                      {typeof window !== 'undefined' && (
                        <TwoColumnWithIcons
                          key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}
                          sectionTitle={content?.twoColumnWithIconsSectionTitle}
                          sectionDescription={content?.twoColumnWithIconsDescription}
                          sectionImage={content?.twoColumnWithIconsFeaturedImage.localFile}
                          contentItems={content?.twoColumnWithIconsContent}
                        />
                      )}
                    </React.Fragment>
                  );
                }

                case 'page_Acfuniversalcontent_FlexibleContent_ProjectGallery': {
                  return (
                    <React.Fragment key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}>
                      {typeof window !== 'undefined' && (
                        <ProjectGallery
                          key={`${fieldGroupName}_${flexibleContent.indexOf(content)}`}
                          sectionTitle={content?.projectGallerySectionTitle}
                          sectionDescription={content?.projectGalleryDescription}
                          contentItems={content?.projectGalleryProjects}
                        />
                      )}
                    </React.Fragment>
                  );
                }

                default:
                  return null;
              }
            };
            return renderSections(content.fieldGroupName);
          })
        ) : (
          <div className="ves-container text-center p-10">
            <h3 className="h3">No Page Content Added</h3>
          </div>
        )}
      </Layout>
    </>
  );
}

export const query = graphql`
  query($id: String!) {
    page: allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
        acfGlobalContent {
          prefooterTitle
          prefooterButtonText
          prefooterButtonLink {
            url
          }
          metaTitle
          metaDescription
          metaOgImage {
            sourceUrl
          }
        }
        acfUniversalContent {
          bannerTitle
          bannerSubtitle
          bannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 90, width: 1920)
              }
            }
          }
          showBreadcrumbs
          flexibleContent {
            ... on WpPage_Acfuniversalcontent_FlexibleContent_Cta {
              ctaBackgroundColor
              ctaButtonText
              ctaDescription
              ctaDownloadLink
              ctaImagePosition
              ctaTitle
              fieldGroupName
              ctaFeaturedImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 90, width: 375)
                  }
                }
              }
              showCtaSection
            }
            ... on WpPage_Acfuniversalcontent_FlexibleContent_TwoColumnContentGrid {
              fieldGroupName
              content {
                title
                videoUrl
                textContent
                image {
                  localFile {
                    publicURL
                  }
                }
                imageOption
                ctaLink {
                  title
                  url
                }
              }
            }
            ... on WpPage_Acfuniversalcontent_FlexibleContent_TabbedNavigationSection {
              fieldGroupName
              sectionTitle
              featuredLogos {
                image {
                  localFile {
                    publicURL
                  }
                  id
                }
              }
              tabsContent {
                tabTitle
                content
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 90, width: 375)
                    }
                  }
                }
              }
            }
            ... on WpPage_Acfuniversalcontent_FlexibleContent_PartnersSection {
              fieldGroupName
              partnersDescription
              partnersTitle
              partnersLogos {
                logo {
                  localFile {
                    id
                    publicURL
                  }
                }
              }
            }
            ... on WpPage_Acfuniversalcontent_FlexibleContent_FloatingCardsGrid {
              fieldGroupName
              floatingCardsCardsPrimaryColor
              floatingCardsLinkText
              floatingCardsNumberOfCards
              floatingCardsSectionBackgroundColor
              floatingCardsSectionLink
              floatingCardsSectionTitle
              floatingCardsDescription
              floatingCardsFeaturedIcon {
                localFile {
                  publicURL
                  id
                }
              }
              floatingCardsContent {
                buttonLink
                buttonText
                content
                title
                icon {
                  localFile {
                    publicURL
                    id
                  }
                }
              }
            }
            ... on WpPage_Acfuniversalcontent_FlexibleContent_NumberedFloatingCards {
              description
              fieldGroupName
              sectionTitle
              floatingCardsContent {
                title
                icon {
                  localFile {
                    publicURL
                  }
                }
              }
            }
            ... on WpPage_Acfuniversalcontent_FlexibleContent_FeaturesSection {
              fieldGroupName
              featuresTitle
              featuresDescription
              featuresLogoContent {
                description
                icon {
                  localFile {
                    publicURL
                  }
                }
              }
            }
            ... on WpPage_Acfuniversalcontent_FlexibleContent_Products {
              fieldGroupName
              sectionTitle
            }
            ... on WpPage_Acfuniversalcontent_FlexibleContent_PaymentMethods {
              fieldGroupName
              paymentMethodsConditions
              paymentMethodsMainContent {
                buttonLink
                content
                title
                icon {
                  localFile {
                    publicURL
                  }
                }
              }
              paymentMethodsSpecialFeatures {
                buttonLink
                color
                description
                title
                icon {
                  localFile {
                    publicURL
                  }
                }
              }
            }
            ... on WpPage_Acfuniversalcontent_FlexibleContent_FullWidthGallery {
              fieldGroupName
              title
              video
              image {
                localFile {
                  publicURL
                }
              }
            }
            ... on WpPage_Acfuniversalcontent_FlexibleContent_Packages {
              fieldGroupName
              packagesDescription
              packagesSectionTitle
              packagesDetails {
                themeColor
                supplyAndInstallKitPrice
                packageName
                diyKitPrice
                capacity
                buttonLink {
                  url
                }
                packageDetailsRepeaterContent {
                  title
                  description
                }
              }
            }
            ... on WpPage_Acfuniversalcontent_FlexibleContent_BasicContentSection {
              fieldGroupName
              basicContentBackgroundColor
              basicContentDescription
              basicContentEditorOne
              basicContentEditorTwo
              basicContentOneColumnTwoColumns
              basicContentSectionTitle
            }
            ... on WpPage_Acfuniversalcontent_FlexibleContent_ContactForm {
              fieldGroupName
              description
              tagline
              title
              featuredImage {
                localFile {
                  publicURL
                }
              }
            }
            ... on WpPage_Acfuniversalcontent_FlexibleContent_ContentSectionWithIcons {
              fieldGroupName
              contentSectionIconsBackgroundColor
              contentSectionIconsSectionTitle
              contentSectionIconsSectionTitleWidth
              contentSectionIconsSectionFeaturedIcon {
                localFile {
                  publicURL
                }
              }
              contentSectionIconsContent {
                description
                title
                primaryColor
                icon {
                  localFile {
                    publicURL
                  }
                }
              }
            }
            ... on WpPage_Acfuniversalcontent_FlexibleContent_TwoColumnContentSectionWithIcons {
              fieldGroupName
              twoColumnWithIconsDescription
              twoColumnWithIconsSectionTitle
              twoColumnWithIconsFeaturedImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 90, width: 312)
                  }
                }
              }
              twoColumnWithIconsContent {
                title
                description
                icon {
                  localFile {
                    publicURL
                  }
                }
              }
            }
            ... on WpPage_Acfuniversalcontent_FlexibleContent_ProjectGallery {
              fieldGroupName
              projectGalleryDescription
              projectGallerySectionTitle
              projectGalleryProjects {
                projectName
                description
                images {
                  id
                  altText
                  caption
                  sourceUrl
                  mediaDetails {
                    sizes {
                      height
                      width
                      name
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    wpPage: wpPage(id: { eq: $id }) {
      wpParent {
        node {
          id
          slug
          uri
          ... on WpPage {
            id
            title
            uri
            slug
            wpParent {
              node {
                uri
                slug
                id
                ... on WpPage {
                  id
                  title
                  uri
                }
              }
            }
          }
        }
      }
      title
      uri
      slug
    }
  }
`;
