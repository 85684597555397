import React from 'react';
import PropTypes from 'prop-types';

import { BcWrpStyled } from './basiccontent.styled';

export default function BasicContent({
  twoCol,
  gray,
  title,
  description,
  basicContentEditorOne,
  basicContentEditorTwo,
}) {
  return (
    <>
      <BcWrpStyled>
        <div className={`bc-bg-wrp ${gray ? 'gray' : ''}`}>
          <div className="bc-section-wrp ves-container">
            <div className="bc-description-wrp">
              {title && <h2 className="h2">{title}</h2>}
              {description && <div className="tagline">{description}</div>}
            </div>
            <div className={`bc-content-wrp ${twoCol ? 'has-two-column' : ''}`}>
              {/* eslint-disable react/no-danger */}
              <div
                className="content-section one column-list"
                dangerouslySetInnerHTML={{ __html: basicContentEditorOne }}
              />

              {twoCol ? (
                <div
                  className="content-section two column-list"
                  dangerouslySetInnerHTML={{ __html: basicContentEditorTwo }}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </BcWrpStyled>
    </>
  );
}

BasicContent.propTypes = {
  //   bgColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  basicContentEditorOne: PropTypes.string,
  basicContentEditorTwo: PropTypes.string,
  twoCol: PropTypes.bool,
  gray: PropTypes.bool,
};
