import tw, { styled } from 'twin.macro';
// eslint-disable-next-line import/no-extraneous-dependencies
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const GalleryComponantWrpStyled = styled.div`
  ${tw`relative`}
  z-index: 2;

  .gallery-section-wrp {
    h2 {
      ${tw`text-primary-dark text-center lg:text-left pb-12 lg:pb-20 mx-auto lg:mx-0`}

      @media (min-width: ${fullConfig.theme.screens.lg}) {
        max-width: 545px;
      }
    }
  }
`;
