import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import ProductCard from '../product-card';

import { ProductsListStyled, SectionHeaderStyled } from './products.styled';

export function ProductsJXS({ data, sectionTitle }) {
  const productsList = data?.allWpProduct?.nodes;

  return (
    <ProductsListStyled className="ves-section">
      <div className="ves-container">
        <SectionHeaderStyled>
          <h2 className="h2">{sectionTitle}</h2>
        </SectionHeaderStyled>

        <div className="grid  md:grid-cols-3 gap-3 product-list">
          {productsList.map((product) => {
            return (
              <ProductCard
                key={product.id}
                title={product.title}
                description={product.acfProducts.briefDescription}
                image={product.featuredImage?.node?.localFile}
                link={product.acfProducts.productItemLink?.url}
              />
            );
          })}
        </div>
      </div>
    </ProductsListStyled>
  );
}

export default function Products(props) {
  return (
    <StaticQuery
      query={graphql`
        query Products {
          allWpProduct {
            nodes {
              acfProducts {
                briefDescription
                productItemLink {
                  url
                }
              }
              title
              id
              link
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 90, width: 380, height: 312)
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <ProductsJXS data={data} {...props} />}
    />
  );
}

ProductsJXS.propTypes = {
  sectionTitle: PropTypes.string,
};
