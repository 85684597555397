import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import Link from '../Link';

import { PaymentMethodsStyled } from './payment-methods.styled';

export default function PaymentMethods({
  sectionTitle,
  sectionDescription,
  sectionButtonLink,
  sectionIcon,
  sectionConditions,
  cardContent,
}) {
  return (
    <>
      <PaymentMethodsStyled className="ves-section">
        <div className="solutions-outer-wrp ves-container">
          <div className="solutions-inner-wrp">
            <div className="solution-content-wrp">
              {sectionIcon && <img src={sectionIcon} alt="section-icon" />}
              {sectionTitle && <h2 className="h2">{sectionTitle}</h2>}
              {sectionDescription && <div className="content">{sectionDescription}</div>}
              {sectionButtonLink && (
                <Link className="btn contact-us" to={sectionButtonLink}>
                  Apply today
                </Link>
              )}
            </div>
            <div className="solution-type-cards-wrp">
              {cardContent.map((item) => (
                <div
                  key={uuidv4()}
                  className={`solution-card-item ${
                    item.color === 'blue' ? 'bg-primary-dark' : 'bg-success'
                  }`}>
                  <div className="content-wrp">
                    {item.icon && (
                      <img
                        src={item.icon.localFile.publicURL}
                        className="card-item-image"
                        alt="card-icon"
                      />
                    )}
                    {item.title && <h3 className="h3">{item.title}</h3>}
                    {item.description && (
                      <div className="content p-sm">
                        <p>{item.description}</p>
                      </div>
                    )}
                  </div>
                  {item.buttonLink && (
                    <div className="button-wrp">
                      <Link to={item.buttonLink} className="learn-more">
                        Learn How
                      </Link>
                    </div>
                  )}
                </div>
              ))}

              {sectionConditions && (
                <div className="conditions col-span-2 mt-6">
                  <h6>Conditions</h6>
                  <p>{sectionConditions}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </PaymentMethodsStyled>
    </>
  );
}
