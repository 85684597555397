import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { FeaturesStyled, SectionHeaderStyled, FeatureStyled } from './features.styled';

export function FeatureCard({ text, icon }) {
  return (
    <FeatureStyled className="group">
      <div className="content">
        {icon && <img src={icon} alt="feature-icon" layout="fixed" />}
        {text && <p className="p">{text}</p>}
      </div>
    </FeatureStyled>
  );
}

export function Features({ sectionTitle, sectionDescription, logoContent }) {
  return (
    <FeaturesStyled className="">
      <div className="ves-container">
        <SectionHeaderStyled>
          {sectionTitle && <h2 className="h2">{sectionTitle}</h2>}
          {sectionDescription && <p>{sectionDescription}</p>}
        </SectionHeaderStyled>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
          {logoContent?.map((item) => (
            <FeatureCard
              key={uuidv4()}
              text={item.description}
              icon={item.icon?.localFile?.publicURL}
            />
          ))}
        </div>
      </div>
    </FeaturesStyled>
  );
}

export default Features;
