import React from 'react';

import Link from '../Link';
import FloatingCard from '../floating-card';

import { FloatingCardGridStyled, SectionHeaderStyled } from './floating-card-grid.styled';

export function FloatingCardGrid({
  colCount,
  sectionTitle,
  sectionDescription,
  featuredIcon,
  primaryColor,
  cards,
  linkText,
  sectionLink,
  sectionBackgroundColor,
}) {
  return (
    <FloatingCardGridStyled
      className={`ves-section ${sectionBackgroundColor ? 'has-no-background' : 'has-background'}`}
      colored={sectionBackgroundColor}>
      <div className="ves-container">
        <SectionHeaderStyled>
          <img src={featuredIcon} alt="A kitten" layout="fixed" />
          {sectionTitle && (
            <h2 className={`h2 ${primaryColor === 'dark-blue' ? 'text-primary-dark' : ''}`}>
              {sectionTitle}
            </h2>
          )}
          {/* <h4 className="h4 tagline">{sectionDescription}</h4> */}
          {/* eslint-disable react/no-danger */}
          {sectionDescription && (
            <div className="tagline" dangerouslySetInnerHTML={{ __html: sectionDescription }} />
          )}
        </SectionHeaderStyled>

        <div
          className={`grid sm:grid-cols-2 lg:grid-cols-${colCount / 2} grid-container ${
            // eslint-disable-next-line eqeqeq
            colCount == 6 ? 'constrain' : null
          }`}>
          {cards.map((item) => (
            <FloatingCard
              dark={primaryColor === 'dark-blue'}
              key={item.content}
              title={item.title}
              buttonLink={item.buttonLink}
              buttonText={item.buttonText}
              content={item.content}
              icon={item.icon.localFile.publicURL}
            />
          ))}
        </div>
        {sectionLink && (
          <div className="section-link">
            <Link
              dark={primaryColor === 'dark-blue'}
              to={sectionLink}
              className={`more ${
                primaryColor === 'dark-blue' ? 'text-primary-dark' : 'text-primary'
              }`}>
              {linkText}
            </Link>
          </div>
        )}
      </div>
    </FloatingCardGridStyled>
  );
}

export default FloatingCardGrid;
