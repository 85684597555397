import tw, { styled } from 'twin.macro';
// eslint-disable-next-line import/no-extraneous-dependencies
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const TwoColWithIconsWrpStyled = styled.div`
  .twocol-section-wrp {
    ${tw`grid grid-cols-1 xl:grid-cols-12 relative mx-auto items-center xl:items-start`}

    .left-col {
      ${tw`xl:col-span-5 flex justify-center items-center mb-12`}
    }

    .right-col {
      ${tw`xl:col-span-7 text-center xl:text-left`}

      .title-wrp {
        ${tw`mb-12 mx-auto`}
        max-width: 684px;

        h2 {
          ${tw`font-bold text-primary-dark mb-10`}
          font-size: 32px;
          line-height: 40px;

          @media (min-width: ${fullConfig.theme.screens.md}) {
            font-size: 28px;
          }

          @media (min-width: ${fullConfig.theme.screens.xl}) {
            font-size: 32px;
          }
        }

        p {
          ${tw`leading-8 text-gray-dark`}
        }
      }

      .content {
        ${tw`md:grid md:grid-cols-2 items-start`}

        .content-col {
          ${tw`flex flex-col items-center justify-center mb-14 mx-auto xl:items-start xl:ml-0`}
          max-width: 265px;

          @media (min-width: ${fullConfig.theme.screens.xl}) {
            max-width: 280px;
          }

          .icon {
            ${tw`bg-primary flex items-center justify-center mb-5`}
            width: 82px;
            height: 82px;
            border-radius: 20px;
          }

          h4 {
            ${tw`text-primary text-xl font-bold uppercase`}
          }

          p {
            ${tw`leading-8 text-gray-darker`}
          }
        }
      }
    }
  }
`;
