import React from 'react';

import Link from '../Link';

import { FloatingCardStyled } from './floating-card.styled';

function FloatingCard({ dark, title, buttonLink, buttonText, content, icon }) {
  return (
    <FloatingCardStyled className="group" dark={!!dark}>
      <div className="content mb-4">
        <img src={icon} alt="featured-icon" layout="fixed" />
        <h6>{title}</h6>

        <p className="p">{content}</p>
        {buttonLink && (
          <Link to={buttonLink} className="more">
            {buttonText}
          </Link>
        )}
      </div>
    </FloatingCardStyled>
  );
}

export default FloatingCard;
