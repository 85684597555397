import tw, { styled } from 'twin.macro';
// eslint-disable-next-line import/no-extraneous-dependencies

export const ProjectGalleryComponantWrpStyled = styled.div`
  ${tw`relative`}
  z-index: 2;

  .gallery-section-wrp {
    .section-title {
      ${tw`text-center font-bold text-primary-dark pb-4`}
      font-size: 32px;
      line-height: 40px;
    }
    .section-description {
      ${tw`text-center text-gray-dark leading-8 pb-12`}
    }
  }
`;
