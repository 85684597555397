import tw, { styled } from 'twin.macro';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const FloatingNumberedCardStyled = styled.div`
  ${tw`flex flex-col items-center px-5 relative w-full`};
  z-index: 1;
  max-width: 232px;
  min-height: 280px;

  &::after {
    content: '';
    z-index: 0;
    ${tw`flex absolute bg-gradient-to-t from-success-light to-success opacity-0 group-hover:opacity-100 w-full h-full transition transition-all ease-in-out duration-200 top-0 scale-100 shadow-none`};
  }

  .content {
    ${tw`flex flex-col items-start z-10`};
  }

  .title-block {
    height: 105px;
  }

  h6 {
    ${tw`text-success transition transition-all ease-in-out duration-200 relative font-bold ml-0 transform`};
    font-size: 72px;
    /* ${(props) => {
      if (props.dark === true) {
        return tw`text-primary-dark`;
      }
      return tw`text-primary`;
    }} */
  }

  p {
    ${tw`text-gray-darker text-xl group-hover:text-white transition transition-all ease-in-out duration-200 relative transform scale-75 font-semibold`};
    margin-top: 15px;
  }

  .gatsby-image-wrapper {
    ${tw`mt-1`};
  }

  .more {
    ${tw`leading-3 text-white tracking-widest uppercase font-bold relative flex items-center text-xs transition transition-all ease-in-out duration-200 opacity-0 mt-0 mb-4`}

    &::after {
      content: '';
      display: block;
      background-image: url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2711.05%27 height=%2710.77%27 viewBox=%270 0 11.05 10.77%27%3E%3Cpath id=%27Icon_awesome-arrow-right%27 data-name=%27Icon awesome-arrow-right%27 d=%27M4.7,3.369l.547-.547a.589.589,0,0,1,.836,0l4.794,4.792a.589.589,0,0,1,0,.836L6.082,13.243a.589.589,0,0,1-.836,0L4.7,12.7a.593.593,0,0,1,.01-.846L7.68,9.019H.592A.59.59,0,0,1,0,8.427V7.638a.59.59,0,0,1,.592-.592H7.68L4.708,4.215A.588.588,0,0,1,4.7,3.369Z%27 transform=%27translate%280 -2.647%29%27 fill=%27%23fff%27/%3E%3C/svg%3E%0A');
      width: 12px;
      height: 12px;
      background-repeat: no-repeat;
      margin-left: 8px;
    }
  }

  &:hover {
    ${tw`z-10`};

    &::after {
      ${tw`bg-gradient-to-b from-success-light to-success rounded-2xl opacity-100`};
      content: '';
      width: calc(100% + 20px);
      height: calc(100% + 30px);
      left: -10px;
      top: -15px;
      box-shadow: 0px 40px 50px #70b32f4d;
    }

    h6 {
      ${tw`text-white scale-100`};
      font-size: 92px;
    }

    p {
      ${tw`text-white scale-100`};
      margin-top: 10px;
    }

    .more {
      ${tw`opacity-100 mt-4 mb-0`}
    }
  }

  @media (max-width: ${fullConfig.theme.screens.lg}) {
    &.is-selected {
      ${tw`z-10`};

      &::after {
        ${tw`bg-gradient-to-b from-success-light to-success rounded-2xl opacity-100`};
        content: '';
        width: calc(100% + 20px);
        height: calc(100% + 30px);
        left: -10px;
        top: -15px;
        box-shadow: 10px 40px 50px #000d3f33;
      }

      h6 {
        ${tw`text-white scale-100`};
        font-size: 92px;
      }

      p {
        ${tw`text-white scale-100`};
        margin-top: 10px;
      }

      .more {
        ${tw`opacity-100 mt-4 mb-0`}
      }
    }
  }
`;

export const FloatingNumberedCardSliderStyled = styled.section`
  ${tw`block relative overflow-x-hidden`};

  .grid-container {
    ${tw`relative`};

    &::after {
      content: '';
      ${tw`bg-white rounded-2xl absolute w-full h-full`};
      box-shadow: 0px 40px 50px #70b32f4d;
      z-index: -1;
    }
  }

  .constrain {
    ${tw`mx-auto`}
    max-width: 1007px;
  }

  .flickity-viewport {
    overflow: visible;
  }

  .floating-carousel {
    ${tw`block`}
  }

  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;

    > div {
      counter-increment: carousel-cell;
      max-width: 232px;
      width: 100%;

      &::before {
        content: '';
        z-index: -1;
        ${tw`w-full h-full flex absolute top-0`};
      }

      &:first-child {
        &:before {
          ${tw`rounded-l-2xl overflow-hidden`}
        }
      }

      &:last-child {
        &::before {
          ${tw`rounded-r-2xl overflow-hidden`}
        }
      }

      &::before {
        background: #f4f4f4;
      }

      &:nth-child(even) {
        &:before {
          background: #fafafa;
        }
      }
    }
  }

  /* draggable */

  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .flickity-button {
    position: absolute;
  }
`;

export const SectionHeaderStyled = styled.div`
  ${tw`flex flex-col relative items-center text-center pb-12 md:pb-14 mx-auto`};
  max-width: 768px;

  h2 {
    ${tw`mb-3 text-primary-dark`};
  }

  .tagline {
    ${tw`font-bold leading-8 text-primary-dark`};
  }

  p {
    ${tw`text-gray`};
  }

  .gatsby-image-wrapper {
    ${tw`mb-4`};
  }
`;
