import React from 'react';
// import PropTypes from 'prop-types';

import Gallery from '../gallery';

import { GalleryComponantWrpStyled } from './gallerycomponant.styled';

export default function GalleryComponant({ title, image, videoLink }) {
  return (
    <>
      <GalleryComponantWrpStyled className="gallery-section ves-section">
        <div className="gallery-section-wrp ves-container">
          {title && <h2 className="h2">{title}</h2>}
          <Gallery image={image} video={videoLink} />
        </div>
      </GalleryComponantWrpStyled>
    </>
  );
}

// GalleryComponant.propTypes = {
//   image: PropTypes.string.isRequired,
//   video: PropTypes.string.isRequired,
// };
