import tw, { styled } from 'twin.macro';
// eslint-disable-next-line import/no-extraneous-dependencies
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const BcWrpStyled = styled.div`
  .bc-bg-wrp {
    ${tw`pt-28 pb-28`}

    &.gray {
      ${tw`bg-gray-lighter`}
    }

    .bc-section-wrp {
      ${tw`relative`}

      .bc-description-wrp {
        ${tw`text-center mb-7 md:mb-12 md:mx-auto`}

        @media (min-width: ${fullConfig.theme.screens.md}) {
          max-width: 544px;
        }

        h2 {
          ${tw`text-primary-dark md:mb-5 lg:mb-5`}
        }

        .tagline {
          ${tw`leading-8 lg:leading-7 text-gray-dark`}
        }
      }

      .bc-content-wrp {
        &.has-two-column {
          ${tw`grid grid-cols-1 md:grid-cols-2 md:gap-12 lg:gap-24 xl:gap-56`}
        }

        .column-list {
          ul {
            li {
              ${tw`relative pl-6 mb-4 md:text-lg`}

              &::after {
                ${tw`absolute top-2 left-0 w-2 h-2 block bg-primary`}
                content: '';
                border-radius: 50%;
              }
            }
          }

          ol {
            counter-reset: my-awesome-counter;

            li {
              ${tw`relative pl-6 mb-4 md:text-lg`}
              counter-increment: my-awesome-counter;

              &::before {
                ${tw`text-primary absolute top-0 left-0`}
                content: counter(my-awesome-counter) '. ';
                font-weight: bold;
              }
            }
          }
        }

        .content-section {
          ${tw`text-gray-darker mb-8`}

          h4 {
            ${tw`mb-5 font-bold text-xl ml-6`}
          }

          &:last-child {
            ${tw`mb-0`}
          }
        }
      }
    }
  }
`;
