import tw, { styled } from 'twin.macro';
// eslint-disable-next-line import/no-extraneous-dependencies
// import resolveConfig from 'tailwindcss/resolveConfig';

// import tailwindConfig from '../../../tailwind.config';

// const fullConfig = resolveConfig(tailwindConfig);

export const CSWrpStyled = styled.div`
  .cs-bg-wrp {
    ${tw`pt-28 pb-20 md:pb-28`}

    &.gray {
      ${tw`bg-gray-lighter`}
    }

    .cs-inner-wrp {
      .description-wrp {
        .image-wrp {
          ${tw`mb-7 flex justify-center lg:justify-start`}
        }

        h2 {
          ${tw`mb-16 text-center lg:text-left text-primary-dark`}

          &.narrow {
            max-width: 474px;
            ${tw`mx-auto lg:ml-0`}
          }
        }
      }

      .cs-section-wrp {
        ${tw`col-span-2 grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-14 items-start`}

        .about-content-item {
          ${tw`text-center lg:text-left flex flex-col items-center lg:items-start mb-16 md:mb-0`}

          .image-wrp {
            ${tw`w-32 h-32 rounded-2xl flex justify-center items-center mb-8`}
            box-shadow: -10px -10px 30px #FFFFFF;
          }

          h4 {
            ${tw`mb-5 uppercase`}
          }

          .content {
            ${tw`text-primary-dimGray leading-7`}
          }

          .text-secondary-orange {
            ${tw`text-secondary-orange`}
          }
        }

        &.four-columns {
          ${tw`col-span-2 grid grid-cols-1 md:grid-cols-4 md:gap-4 lg:gap-14 items-start`}

          .about-content-item {
            .image-wrp {
              ${tw`w-20 h-20 md:w-14 md:h-14 lg:w-20 lg:h-20 p-4 md:p-3 lg:p-4 mb-4 lg:mb-7`}
            }

            h4 {
              ${tw`text-base lg:text-xl mb-5 lg:mb-7`}
            }
          }
        }
      }
    }
  }
`;
